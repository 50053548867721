<template>
  <b-container class="page-container">
    <h1 class="text-center">{{ this.$t('confirmation-checkout') }}</h1>

    <h4>
      {{ this.$t('overview-of-your-order') }}
      <small class="text-danger">{{
        this.$t('Let op! Jouw reservatie is pas definitief na betaling.')
      }}</small>
    </h4>
    <b-row>
      <b-col md="4">
        <b-card class="h100">
          <div>
            <strong>{{ this.$t('reservation-id') }}</strong>
            <p>{{ reservation.ReservationID }}</p>
          </div>
          <div>
            <strong>{{ this.$t('retrieval-location') }}</strong>
            <p>{{ this.$t(reservation.WarehouseID) }}</p>
          </div>
          <div>
            <strong>{{ this.$t('retrieval-date') }}</strong>
            <p>
              {{ format(reservation.DateTimeBusinessStart) }}
            </p>
          </div>
          <div>
            <strong>{{ this.$t('return-date') }}</strong>
            <p>
              {{ format(reservation.DateTimeExpectedEnd) }}
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-table :items="reservation.ReservationItems" :fields="fields">
          <template v-slot:cell(DbTotalExcVAT)="data">
            <div class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.TotalIncVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <table class="table">
          <tr>
            <td class="text-right">
              <strong>{{ this.$t('total-amount') }}:</strong>
            </td>
            <td class="text-right" width="140px">
              &euro;
              {{
                reservation.TotalIncVAT.toFixed(2)
                  .toString()
                  .replace('.', ',')
              }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12">
        <b-card class="mt-2">
          <a
            :href="
              `/${this.$i18n.locale}/betaling/${reservation.ReservationOrderID}`
            "
            class="btn btn-primary float-right"
            >{{ this.$t('click-to-pay') }}
            <font-awesome-icon :icon="['fas', 'credit-card']" class="ml-1" fas
          /></a>
          <h2 class="pt-2">
            {{ this.$t('pay-your-reservation') }}
          </h2>
        </b-card>
      </b-col>
      <b-col cols="12" class="mt-3">
        <hr />
        <h3>{{ this.$t('order-more') }}</h3>

        <router-link
          :to="{ name: 'index' }"
          class="btn btn-primary"
          variant="primary"
          >{{ this.$t('place-new-reservation') }}
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default {
  components: {},
  data() {
    return {
      fields: [
        {
          key: 'ItemDescription',
          label: this.$t('product')
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'Amount',
          label: this.$t('amount'),
          class: 'text-center'
        },
        {
          key: 'DbTotalExcVAT',
          label: this.$t('total'),
          class: 'text-right'
        }
      ]
    }
  },
  computed: {
    reservation: function() {
      let reservationData = this.$store.getters.reservation.data

      reservationData.ReservationItems = reservationData.ReservationItems.filter(
        function(obj) {
          return obj.Description !== 'Administratiekosten'
        }
      )
      return reservationData
    }
  },
  methods: {
    format(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.prices {
  padding-right: 10px;
}
h1 {
  font-size: 50px;
}
h3 {
  font-size: 35px;
}
.card,
p,
.table {
  font-size: 18px;
}
.card {
}
a {
  text-decoration: none;
}
.price-values {
  p {
    padding-bottom: 3px !important;
  }
}
</style>
